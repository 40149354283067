@import 'src/styles/variables.module.scss';

.container {
  margin-top: 56px;
  margin-left: 56px;
  margin-right: 56px;
  width: 1002px;
}

.breadAddformContainer {
  margin-left: 158px;
  width: 632px;
}

.breadAddButtonContainer {
  margin-top: 180px;
  margin-bottom: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shopInfoButtonTextContainer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $daseruno-red;
  font-weight: 600;
}

.shopInfoButtonTextUnderContainer {
  margin-top: 20px;
  margin-bottom: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shopInfoButtonContainer {
  margin-top: 70px;
  margin-bottom: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalButtonContainer {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.breadAddButtonSpacer {
  margin-left: 30px;
}

.dashboardPageHeadContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dashboardSearchContainer {
  width: 1002px;
  height: 200px;
  margin-top: 48px;
}

.dashboardSearchRow {
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboardSearchRowSection {
  margin-left: 8px;
  margin-right: 12px;
  height: 118px;
  border-right: 2px solid $daseruno-light-gray;

  &_middle {
    @extend .dashboardSearchRowSection;
    height: 70px;

  }
}

.dashboardSuperAdminSpacer {
  margin-top: 158px;
}

.dashboardTableNotes {
  margin-top: 9px;
  font-size: 12px;
  color: $daseruno-red;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dashboardButtonContainer {
  margin-top: 64px;
  margin-bottom: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dashboardButtonWrapContainer {
  margin-top: 64px;
  margin-bottom: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboardButtonSpacer {
  margin-left: 34px;
}

.addressSearchButton {
  height: 54px;
  background-color: $daseruno-light-gray;
  border: none;
  border-radius: 4px;
  margin-left: 20px;
}

.zipCodeSearchErrorText {
  position: absolute;
  margin-left: 20px;
  margin-top: 10px;
  color: $daseruno-red;
  font-weight: bold;
}

.previewImageContainer {
  display: flex;
  flex-wrap: wrap;
}

.previewImageErrorText {
  margin-left: 60px;
  margin-top: 12px;
  color: $daseruno-red;
  font-weight: bold;
}

.breadCategoryContainer {
  width: 670px;
  height: 82px;
}

.flexColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sendEmailButton {
  text-decoration-line: underline;
  color: $daseruno-blue;
  margin-top: 8px;
  cursor: pointer;
  width: fit-content;
}

.emailVerifyStatus {
  margin-left: auto;
  background-color: $daseruno-gray1;
  color: $white;
  border-radius: 32px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
}

.bread_detail {
  width: 1002px;

  &_container {
    width: 1114px;
  }

  &_space {
    margin-left: 8px;

    &_wide {
      @extend .bread_detail_space;
      margin-left: 24px;
    }
  }

  &_display_unit {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &_box {
      width: 697px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    &_container {
      width: 264px;
      height: 59px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &_text {
      font-size: 12px;
      font-weight: 700;
    }
  }

  &_summary {
    @extend .bread_detail;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &_thumbnail {
      width: 203px;
      height: 150px;
    }

    &_info {
      width: 788px;
      height: 270px;

      &_main {
        width: 784px;
        height: 46px;
        margin-bottom: 12px;
        border: 2px solid $daseruno-light-gray;
        border-radius: 4px;
        font-size: 12px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        &_head {
          width: 207px;
          height: 18px;
          font-weight: 700;
        }

        &_head2 {
          width: 60px;
          height: 18px;
          font-weight: 700;
        }

        &_body {
          width: 524px;
          height: 18px;
        }

        &_body2 {
          width: 672px;
          height: 48px;
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
      }

      &_sub {
        width: 788px;
        height: 144px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_box {
          width: 382px;
          min-height: 144px;
          border: 2px solid $daseruno-light-gray;
          border-radius: 4px;
          font-size: 12px;

          &_pad {
            width: 350px;
            height: 118px;
            padding-top: 13px;
            padding-bottom: 13px;
            padding-left: 16px;
            padding-right: 16px;
          }

          &_head {
            width: 350px;
            height: 18px;
            font-weight: 700;
          }

          &_body {
            width: 350px;
            height: 100px;
          }
        }
      }
    }
  }

  &_table {
    @extend .bread_detail;
    min-height: 97px;

    &_center {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_margin_top {
      margin-top: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_margin_bottom {
      margin-bottom: 12px;
    }

    &_add_button {
      margin-left: 20px;
    }

    &_input_and_unit {
      margin-left: 2px;
    }

    &_tab {
      margin-top: 29px;
      width: 157px;
      height: 40px;
      border: 2px solid $daseruno-gray2;
      border-radius: 4px 4px 0 0;
      background-color: $daseruno-gray2;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
    }

    &_container {
      min-height: 80px;
      border: 2px solid $daseruno-light-gray;
      border-radius: 0 4px 4px 4px;
    }

    &_spacer {
      margin-top: 8px;
    }

    &_border {
      border-bottom: 2px solid $daseruno-light-gray;
    }

    &_row {
      padding-right: 32px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 860px;
      min-width: 720px;

      &_gray {
        background: $daseruno-light-gray;
      }

      &_gray_opacity {
        background: $daseruno-light-gray-opacity;
      }

      &_under_line_gray {
        border-bottom: 1px solid $daseruno-light-gray;
      }

      &_under_line_orange {
        border-bottom: 1px solid $daseruno-orange-opacity2;
      }

      &_right_border_gray {
        border-right: 1px solid $daseruno-light-gray;
      }

      &_right_border_orange {
        border-right: 1px solid $daseruno-orange-opacity2;
      }

      &_right_border_white {
        border-right: 1px solid $white;
      }

      &_click_box {
        border: 2px solid $daseruno-light-gray;
        border-radius: 2px;
        width: 42px;
        height: 36px;
      }

      &_margin_left {
        padding-left: 8px;
      }

      &_margin_right {
        padding-right: 8px;
      }

      &_head {
        height: 32px;
        font-size: 12px;
        text-align-last: right;

        &_bold {
          @extend .bread_detail_table_row_head;
          font-weight: 700;
        }
      }

      &_body {
        // height: 32px;
        height: 48px;
        font-size: 12px;
        text-align-last: right;

        &_bold {
          @extend .bread_detail_table_row_body;
          font-weight: 700;

          &_large {
            @extend .bread_detail_table_row_body_bold;
            font-size: 14px;
          }
        }
      }

      // &_point {
      //   width: 4px;
      //   height: 4px;
      //   border: 2px solid $daseruno-orange;
      //   border-radius: 100%;
      //   background: $daseruno-orange;
      //   margin-right: 7px;
      // }

      &_paragraph_down {
        margin-left: 36px;
        // margin-right: -15px;
      }

      // &_category {
      //   // width: 260px;
      //   width: 324px;
      //   display: flex;
      //   justify-content: flex-start;
      //   align-items: center;
      // }

      &_open_close_icon {
        width: 17px;
        height: 17px;
      }

      &_name {
        // width: 204px;
        width: 388px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &_inactive {
          @extend .bread_detail_table_row_name;
          opacity: 0.5;
        }
      }

      &_maker {
        // width: 204px;
        min-width: 120px;
        max-width: 120px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &_inactive {
          @extend .bread_detail_table_row_maker;
          opacity: 0.5;
        }
      }

      &_percent {
        min-width: 56px;
        max-width: 56px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &_inactive {
          @extend .bread_detail_table_row_percent;
          opacity: 0.5;
        }
      }

      &_price {
        min-width: 92px;
        max-width: 92px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &_inactive {
          @extend .bread_detail_table_row_price;
          opacity: 0.5;
        }
      }

      &_action {
        min-width: 48px;
        max-width: 48px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      &_weight {
        margin-left: 20px;
        width: 56px;
        height: 36px;
        padding-left: 8px;
        padding-right: 8px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &_border {
          @extend .bread_detail_table_row_weight;
          border: 2px solid $daseruno-light-gray;
          border-radius: 4px;
        }
      }

      &_unit {
        width: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      &_box_delete_relative {
        position: relative;
      }

      &_box_delete_absolute {
        position: absolute;
        right: -24px;
      }
    }
  }

  &_memo {
    @extend .bread_detail;
    margin-top: 60px;

    &_space {
      margin-top: 30px;
    }
  }

  &_foot {
    @extend .bread_detail;
    margin-top: 20px;
    margin-bottom: 77px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &_button {
      &_small {
        width: 328px;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
      &_middle {
        width: 504px;
        height: 62px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }

    &_info {
      width: 426px;
      height: 164px;
      border: 2px solid $daseruno-light-gray;
      font-weight: 700;
      font-size: 12px;

      &_col1 {
        width: 176px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      &_col2 {
        width: 106px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &_border {
          @extend .bread_detail_foot_info_col2;
          width: 48px;
          padding-top: 8px;
          padding-bottom: 8px;
          padding-left: 12px;
          padding-right: 12px;

          &_on {
            @extend .bread_detail_foot_info_col2_border;
            border: 2px solid $daseruno-light-gray;
            border-radius: 4px;
            background: $white;
          }
        }
      }

      &_col3 {
        width: 56px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      &_col4 {
        width: 38px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      &_row {
        height: 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &_quantity_row {
        height: 59px;
        background: $daseruno-red-opacity;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &_cost_simulation_result {
    margin-top: 48px;
    background: $daseruno-orange-opacity;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 144px;

    &_unit {
      margin-left: 16px;
    }

    &_button {
      margin-top: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.bread_add_recipe {
  width: 1002px;
  position: relative;

  &_display_unit {
    position: absolute;
    transform: translateX(400%) translateY(-210%);
    width: 198px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_detail {
      @extend .bread_add_recipe_display_unit;
      transform: translateX(310%) translateY(30%);
      width: 240px;
      z-index: 10;
    }

    &_simulation {
      @extend .bread_add_recipe_display_unit;
      transform: translateX(400%) translateY(20%);
      z-index: 10;
    }

    &_text {
      font-size: 12px;
      font-weight: bold;
    }
  }

  &_section {
    @extend .bread_add_recipe;
    height: 85px;
    font-size: 18px;
    border-bottom: 2px solid $daseruno-light-gray;
    display: flex;
    align-items: flex-end;
    padding-bottom: 8px;
    margin-bottom: 24px;
  }

  &_mini_section {
    @extend .bread_add_recipe;
    height: 85px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: flex-end;
    padding-bottom: 8px;
  }

  &_call_pattern {
    @extend .bread_add_recipe;
    margin-top: 28px;
    height: 89px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &_select {
      @extend .bread_add_recipe_call_pattern;
      width: 625px;
      position: relative;

      &_clear {
        position: absolute;
        left: 392px;
        top: 50px;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  &_product_information {
    @extend .bread_add_recipe;
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &_sum {
    @extend .bread_add_recipe;
    display: flex;
    justify-content: right;
    align-items: flex-end;
    margin-top: 8px;
    margin-right: 20px;

    &_margin {
      @extend .bread_add_recipe_sum;
      margin-top: 60px;
    }

    &_display_unit {
      width: 156px;
      height: 59px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    &_label {
      margin-bottom: 18px;
      margin-right: 16px;
    }

    &_space {
      min-width: 28px;
    }
  }

  &_memo {
    margin-top: 28px;
  }

  &_disabled_check {
    margin-top: 64px;

    &_box {
      margin-top: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &_submit {
    @extend .bread_add_recipe;
    margin-top: 80px;
    margin-bottom: 161px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
  }
}

.pattern {
  width: 1002px;

  &_head {
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &_category_search {
    width: 670px;
    height: 82px;
  }

  &_table {
    margin-top: 38px;
  }

  &_margin_large {
    margin-left: 560px;
  }
}

.material {
  width: 1002px;

  &_container {
    margin-left: 158px;
    width: 632px;
  }

  &_section {
    width: 632px;
    height: 85px;
    font-size: 18px;
    border-bottom: 2px solid $daseruno-light-gray;
    display: flex;
    align-items: flex-end;
    padding-bottom: 8px;
    margin-bottom: 24px;
  }

  &_flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &_submit {
    margin-top: 180px;
    margin-bottom: 120px;
    display: flex;
    justify-content: center;
    align-items: center;

    &_spacer {
      margin-left: 40px;
    }
  }

  &_delete_confirm {
    &_attention {
      margin-top: 57px;
    }

    &_shop {
      margin-top: 43px;
      width: 1002px;

      &_count {
        font-size: 16px;
        font-weight: 600;
      }

      &_list {
        margin-top: 45px;
        width: 1002px;
        height: 484px;
      }

      &_detail {
        width: 1002px;
        height: 100px;
        margin-top: 48px;
        display: flex;
        align-items: center;

        &_icon {
          width: 80px;
          height: 80px;
        }

        &_text {
          width: 1002px;
          height: 100px;
          margin-top: 20px;
          margin-left: 24px;
          &_shop_name {
            font-size: 14px;
            font-weight: 500;
          }

          &_bread_name {
            margin-top: 4px;
            font-size: 12px;
            font-weight: 700;
            color: $daseruno-red;
          }
        }
      }
    }
  }
}

.login {
  &_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 32px;
  }

  &_logo {
    margin-top: 48px;
    width: 368px;
    height: 106px;
    display: flex;
    justify-content: center;

    &_image {
      width: 368px;
      height: 106px;
      text-align: center;
    }
  }

  &_description {
    color: $daseruno-gray2;
    white-space: break-spaces;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &_title {
      font-size: 24px;
      font-weight: bold;
    }

    &_text {
      font-size: 16px;
      margin-top: 24px;
    }

    &_img {
      width: 100px;
      height: 100px;
    }
  }

  &_form_container {
    width: 780px;
    height: 400px;
    border-radius: 16px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 60px;

    &_height_long {
      @extend .login_form_container;
      height: 560px;
    }

    &_row {
      width: 780px;
      height: 85px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 27px;
    }

    &_text {
      height: 24px;
      text-align: right;
      font-weight: 500;
      font-size: 16px;
      width: 120px;
    }
  }

  &_link_container {
    font-weight: 600;

    &_text {
      @extend .login_link_container;
      color: $daseruno-blue;
      text-decoration: underline;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
    }
  }

  &_button_container {
    border-radius: 4px;
  }
}

.registerplan {
  &_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 54px;
  }

  &_section_title {
    font-size: 20px;
    color: $daseruno-gray2;
    font-weight: bold;
  }

  &_section_subtitle {
    font-size: 16px;
    color: $daseruno-gray2;
    text-align: center;
  }

  &_plan_box {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    padding: 54px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    border-width: 2px;
    border-style: solid;
    position: relative;

    &_red {
      padding: 36px 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 36px;
      width: 90%;
      background-color: $daseruno-red-opacity2;
    }

    &_container {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
    }

    &_head {
      font-size: 30px;
      font-weight: bold;
      color: $daseruno-gray2;
      margin: 0;
    }

    &_text {
      font-size: 14px;
      color: $daseruno-gray2;
      margin: 0;
      width: 300px;
      text-align: center;
    }

    &_circle {
      background-color: $daseruno-orange;
      width: 120px;
      height: 120px;
      border-radius: 60px;
      color: white;
      font-size: 20px;
      font-weight: bold;
      position: absolute;
      top: -60px;
      right: -60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_input_label {
      font-weight: bold;
      color: $daseruno-gray2;
      margin-bottom: 8px;
    }

    &_input {
      padding: 20px 24px;
      border: 2px solid $daseruno-light-gray;
      border-radius: 4px;
      height: 64px;
      box-sizing: border-box;
    }
  }

  &_standard_plan {
    position: relative;
    &_title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -120%);
      font-size: 24px;
      font-weight: bold;
      color: $daseruno-gray2;
  
       &_image {
          width: 636px;
          height: 60px;
       }
    }

    &_section_header {
      padding: 8px;
      margin-top: 36px;
      background-color: $daseruno-red;
      border: 2px solid $daseruno-red;
      border-radius: 4px;
      color: $white;
      font-weight: bold;
      font-size: 20px;
      width: 100%;
      text-align: center;
    }

    &_flex {
      display: flex;
      justify-content: space-between;

      &_item {
        margin-left: 24px;
        margin-right: 24px;
      }
    }

    &_price {
      position: relative;
      z-index: 1;

      &_border {
        position: absolute;
        transform: translate(0, -240%);
        width: 436px;
        height: 10px;
        background-color: $daseruno-red-opacity;
        z-index: -1;
      }

      &_text {
        font-weight: bold;
        color: $daseruno-gray2;
        z-index: 1;
  
        &_medium {
          @extend .registerplan_standard_plan_price_text;
          font-size: 24px;
        }
  
        &_mid_large {
          @extend .registerplan_standard_plan_price_text;
          font-size: 48px;
          margin-left: 12px;
        }
  
        &_large {
          @extend .registerplan_standard_plan_price_text;
          font-size: 84px;
          margin-left: 21px;
        }
      }
    }

    &_payment {
      margin-top: 60px;
      &_image {
        width: 136px;
        height: 110px;
      }
    }
  }

  &_plan_name_container {
    width: 898px;
    height: 96px;
    border: 2px solid $daseruno-light-gray;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &_text {
      font-weight: bold;
      font-size: 18px;
      color: $daseruno-gray2;
      &_red {
        @extend .registerplan_plan_name_container_text;
        color: $daseruno-red;
      }
    }
  }

  &_label {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: $daseruno-red;
    color: $white;
    font-weight: bold;
    border-radius: 4px;
    border: 2px solid $daseruno-red;
  }

  &_table {
    margin-top: 16px;

    &_flex {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_center {
      text-align: center;
    }

    &_side {
      padding-top: 8px;
      padding-bottom: 8px;
      width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_main {
      padding-top: 8px;
      padding-bottom: 8px;
      width: 324px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_head {
      height: 40px;
      background-color: $daseruno-light-gray;
    }

    &_body {
      height: 60px;
    }

    &_price {
      height: 300px;
    }

    &_left {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &_right {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &_main_text {
      font-size: 24px;
      font-weight: bold;
      color: $daseruno-gray2;
    }

    &_text {
      font-size: 18px;
      font-weight: bold;
      color: $daseruno-gray2;

      &_red {
        @extend .registerplan_table_text;
        color: $daseruno-red;
      }
    }

    &_sub_text {
      font-size: 14px;
      font-weight: bold;
      color: $daseruno-gray2;

      &_red {
        @extend .registerplan_table_sub_text;
        color: $daseruno-red;
      }
    }

    &_border_left {
      border-left: 1px solid $daseruno-light-gray;
    }

    &_border_top {
      border-top: 1px solid $daseruno-light-gray;
    }

    &_border_right {
      border-right: 1px solid $daseruno-light-gray;
    }

    &_border_bottom {
      border-bottom: 1px solid $daseruno-light-gray;
    }

    &_check_image {
      max-width: 32px;
      max-height: 32px;
    }

    &_plan_box {
      &_radio {
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      &_head {
        font-size: 24px;
        font-weight: bold;
        color: $daseruno-gray2;
        margin: 0;
        text-align: center;
      }
  
      &_text {
        font-size: 14px;
        color: $daseruno-gray2;
        margin: 0;
        width: 300px;
        text-align: center;

        &_red {
          @extend .registerplan_table_plan_box_text;
          color: $daseruno-red;
        }
      }
    }
  }

  &_notice_text {
    text-align: center;
    font-size: 14px;

    &_left {
      @extend .registerplan_notice_text;
      text-align: left;
    }
  }
}

.mypage {
  width: 1002px;
  // height: 428px;
  margin-top: 89px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  &_docs {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
  }

  &_doc_links {
    font-size: 16px;
    margin-top: 24px;
    font-weight: bold;
    color: $daseruno-gray2;
  }
}

.account {
  width: 1002px;
  height: 794px;
  margin-top: 54px;
  display: flex;
  justify-content: center;

  &_icon {
    width: 160px;
    height: 160px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }

  &_close_icon {
    width: 24px;
    height: 24px;
    position: absolute;
    transform: translateX(600%) translateY(-750%);
  }

  &_image_delete_button {
    border: 0;
    background: none;
  }

  &_icon_image {
    width: 160px;
    height: 160px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
  }

  &_upload {
    margin-top: 40px;
    width: 220px;
    height: 96px;
    margin-left: auto;
    margin-right: auto;
  }

  &_upload_text {
    margin-top: 16px;
    font-size: 12px;
    border-radius: 50px;
    color: $daseruno-gray2;
    text-align: center;
  }

  &_upload_error_text {
    margin-top: 12px;
    color: $daseruno-red;
    font-weight: bold;
  }

  &_form {
    margin-top: 64px;
    width: 816px;
    height: 239px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
  }

  &_error_text {
    color: $daseruno-red;
    font-weight: bold;
    margin-bottom: 12px;
  }

  &_note {
    position: absolute;
    font-size: 12px;
    color: $daseruno-red;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &_button {
    margin-top: 116px;
    width: 540px;
    height: 76px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
  }
}

.members {
  width: 1002px;
  height: 794px;
  margin-top: 54px;
  justify-content: center;

  &_table_wrap {
    width: 100%;
    overflow: scroll;
    max-height: 1000px;
  }

  &_table {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $daseruno-light-gray;
  }

  &_role_label {
    width: 104px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 4px;

    &_9 {
      @extend .members_role_label;
      color: $daseruno-red;
      border-color: $daseruno-red;
    }

    &_5 {
      @extend .members_role_label;
      color: $daseruno-light-green;
      border-color: $daseruno-light-green;
    }
  }

  &_email {
    margin-left: 40px;
    margin-right: auto;
  }

  &_edit_button {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;

    &_label {
      font-weight: bold;
      color: $daseruno-orange;
      margin-right: 8px;
    }
  }

  &_button {
    width: fit-content;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
  }

  &_modal {
    @extend .modal;
    left: 310px;
    height: 1000px;
    min-width: 1130px;
    width: calc(100vw - 310px);
    justify-content: flex-start !important;
    padding-top: 200px;

    &_title {
      color: white;
      font-weight: bold;
      font-size: 18px;
    }

    &_email {
      color: white;
      font-weight: bold;
      font-size: 32px;
      margin-top: 68px;
    }

    &_role_label {
      color: white;
      font-size: 14px;
      font-weight: bold;
    }

    &_wrap_label {
      display: flex;
      width: 463px;
      align-items: flex-end;
      margin-top: 70px;
    }

    &_require {
      color: white;
      font-weight: bold;
      background-color: $daseruno-red;
      width: 42px;
      height: 24px;
      border-radius: 4px;
      text-align: center;
      margin-left: 20px;
    }

    &_notification {
      color: white;
      font-size: 12px;
      width: 463px;
      margin-top: 15px;
      &_red {
        @extend .members_modal_notification;
        color: $daseruno-red;
      }
    }
  }
}

.priceCard {
  &_head_section {
    display: flex;
    align-items: center;
    margin-top: 24px;
  }

  &_section_title {
    font-size: 14px;
    font-weight: bold;
    color: $daseruno-gray2;
  }

  &_head {
    &_unit_container {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    &_unit_text {
      font-size: 14px;
      font-weight: bold;
      color: $daseruno-gray2;
    }

    &_radio_unit_text {
      font-size: 12px;
      font-weight: bold;
      margin-left: 24px;
      margin-right: 12px;
      color: $daseruno-gray2;
    }
  }

  &_table {
    @extend .bread_detail;
    height: 316px;
    margin-top: 29px;

    &_container {
      @extend .bread_detail_table;

      &_head {
        border: 2px solid $daseruno-light-gray;
        border-radius: 4px 4px 0 0;
      }

      &_body {
        height: 286px;
        border: 2px solid $daseruno-light-gray;
        border-radius: 0 0 4px 4px;

        textarea {
          resize: none;
          padding: 10px;
          height: 70px;
          box-sizing: border-box;
        }

        input {
          height: 70px;
          box-sizing: border-box;
        }
      }
    }

    &_row {
      padding-left: 32px;
      padding-right: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_gray {
        background: $daseruno-light-gray;
      }

      &_gray_opacity {
        background: $daseruno-light-gray-opacity;
      }

      &_head {
        height: 32px;
        font-weight: 600;
        font-size: 12px;
      }

      &_body {
        height: 60px;
        font-size: 12px;
        padding-top: 16px;
        padding-bottom: 16px;

        &_unit {
          font-weight: bold;
          color: $daseruno-gray2;
          margin-left: 10px;
        }
      }

      &_bread_name {
        width: 214px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &_border {
          @extend .priceCard_table_row_bread_name;
          border: 2px solid $daseruno-light-gray;
          border-radius: 4px;
        }
      }

      &_count {
        width: 73px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &_border {
          @extend .priceCard_table_row_count;
          border: 2px solid $daseruno-light-gray;
          border-radius: 4px;
          width: 42px;
          text-align: end;
          padding-left: 8px;
          padding-right: 8px;
        }
      }

      &_price {
        width: 90px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &_border {
          @extend .priceCard_table_row_price;
          border: 2px solid $daseruno-light-gray;
          border-radius: 4px;
          width: 64px;
          text-align: end;
          padding-left: 8px;
          padding-right: 8px;
        }
      }

      &_description {
        width: 230px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &_border {
          @extend .priceCard_table_row_description;
          border: 2px solid $daseruno-light-gray;
          border-radius: 4px;
        }
      }

      &_allergen {
        width: 171px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &_border {
          @extend .priceCard_table_row_allergen;
          border: 2px solid $daseruno-light-gray;
          border-radius: 4px;
        }
      }

      &_delete {
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: none;
        cursor: pointer;

        img {
          width: 18px;
          height: 20px;
        }
      }

      &_weight {
        margin-left: 20px;
        width: 56px;
        height: 36px;
        padding-left: 8px;
        padding-right: 8px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &_border {
          @extend .bread_detail_table_row_weight;
          border: 2px solid $daseruno-light-gray;
          border-radius: 4px;
        }
      }

      &_unit {
        width: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}

.modal {
  background: rgba(74, 85, 104, 0.9);
  position: absolute;
  top: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_account {
    @extend .modal;
    position: fixed;
    text-align: center;
    left: 0;
    height: 100vh;
    width: 100vw;

    &_front {
      @extend .modal_account;
      z-index: 100;
    }

    &_text {
      font-size: 20px;
      font-weight: bold;
      color: $white;
      white-space: break-spaces;
    }

    &_sub_text {
      font-size: 14px;
      color: $white;
    }
  }

  &_raw_material {
    @extend .modal;
    text-align: center;
    left: 310px;
    height: 1162.5px;
    min-width: 1130px;
    width: calc(100vw - 310px);

    &_container {
      width: 888px;
      min-height: 406px;
      display: flex;
      flex-direction: column;
      text-align: left;
      background-color: $white;
      border-radius: 8px;
      padding: 24px 32px;
      box-sizing: border-box;
      font-size: 14px;
    }

    &_label {
      min-width: 100px;
      font-weight: bold;
      justify-content: center;
      flex-wrap: wrap;
      text-align-last: justify;

      &_large {
        @extend .modal_raw_material_label;
        font-size: 24px
      }
    }
  }

  &_price_card {
    @extend .modal;
    text-align: center;
    left: 310px;
    height: 1162.5px;
    min-width: 1130px;
    width: calc(100vw - 310px);

    &_item {
      background: white;
      width: 196px;
      height: 120px;
      margin-bottom: 24px;
      padding: 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      text-align: left;
    }

    &_text {
      font-weight: bold;

      &_bread_name {
        @extend .modal_price_card_text;
        font-size: 10px;
      }

      &_price {
        @extend .modal_price_card_text;
        font-size: 16px;
        margin-top: auto;
      }

      &_allergen {
        @extend .modal_price_card_text;
        font-size: 10px;
        font-weight: bold;
        transform: scale(0.6);
        transform-origin: 0 0;
        width: calc(100% * 5 / 3);
      }

      &_message {
        @extend .modal_price_card_text;
        margin-top: 20px;
        color: $white;
        font-size: 24px;
      }
    }

    &_image {
      &_delete_confirm {
        width: 166px;
        height: 130px;
      }
    }
  }

  &_material_delete {
    @extend .modal;
    text-align: center;
    left: 0;
    height: 100vh;
    width: 100vw;

    &_text {
      font-weight: bold;

      &_message {
        @extend .modal_material_delete_text;
        margin-top: 20px;
        color: $white;
        font-size: 24px;
      }
    }

    &_image {
      &_confirm {
        width: 166px;
        height: 130px;
      }
    }
  }

  &_pattern_disabled {
    @extend .modal;
    left: 0;
    height: 100vh;
    width: 100vw;

    &_text {
      color: $white;

      &_box {
        @extend .modal_pattern_disabled_text;
        width: calc(100vw - 500px);
        background: $daseruno-red;
        border-radius: 4px;
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 88px;
        padding-right: 88px;

        &_main {
          font-weight: 600;
          font-size: 20px;
        }

        &_spacer {
          margin-top: 18px;
        }

        &_description {
          font-weight: 700;
          font-size: 16px;
        }
      }

      &_detail {
        @extend .modal_pattern_disabled_text;
        margin-top: 24px;
        // modal_pattern_disabled_text_boxの左右padding値を足す
        width: calc(100vw - 500px + 176px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_bar {
          background: $daseruno-red;
          border-radius: 4px;
          width: 13px;
          height: 80px;
        }

        &_box {
          margin-left: 33px;
          // modal_pattern_disabled_text_detailからbarのwigth、margin-left値を引く
          width: calc(100vw - 500px + 176px - 13px - 33px);
          &_main {
            font-weight: 600;
            font-size: 18px;
          }

          &_spacer {
            margin-top: 10px;
          }

          &_description {
            font-weight: 400;
            font-size: 14px;
          }
        }
      }
    }

    &_image {
      &_confirm {
        margin-top: 24px;
        width: 166px;
        height: 130px;
      }
    }
  }

  &_card_security_code {
    &_account {
      @extend .modal;
      background: rgba(255, 255, 255, 0.9);
      position: fixed;
      text-align: center;
      left: 0;
      height: 100vh;
      width: 100vw;

      &_text {
        width: 600px;
        margin-top: 24px;
        text-align: left;
        font-size: 14px;
        color: $daseruno-gray2;
        white-space: break-spaces;
      }
    }
  }

  &_simulation {
    &_internal_capacity {
      @extend .modal;
      height: 100vh;
      width: 100vw;
      margin-left: -310px;

      &_container {
        background: $white;
        height: 648px;
        width: 1002px;

        &_form_box {
          margin-top: -12px;
          margin-left: 168px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
        }
      }
    }
  }
}

.food_nutrition_label {
  &_content {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &_margin_left {
      margin-left: 12px;
    }

    &_label {
      width: 106px;
      color: $daseruno-gray2;
      font-weight: bold;
    }

    &_input {
      padding: 16px;
      border: none;
      border-radius: 2px;
      width: calc(100% - 106px);
      box-sizing: border-box;
      resize: none;
    }

    &_short_input {
      border: none;
      border-radius: 2px;
      height: 46px;
      width: 120px;
      text-align: right;
      padding-left: 24px;
      padding-right: 24px;
      box-sizing: border-box;
    }

    &_box_with_radio_button {
      border: none;
      border-radius: 2px;
      height: 46px;
      width: 124px;
      text-align: right;
      padding-left: 16px;
      padding-right: 16px;
      box-sizing: border-box;
      margin-left: 16px;
    }
  }

  &_arrow_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }

  &_arrow_none {
    width: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  &_text {
    color: $daseruno-gray2;
    font-weight: bold;

    &_red {
      @extend .food_nutrition_label_text;
      color: $daseruno-red;
    }
  }

  &_preview {
    &_container {
      width: 444px;
      height: 606px;
      display: flex;
      flex-direction: column;
      text-align: left;
      background-color: $white;
      border-radius: 8px;
      padding: 24px 32px;
      box-sizing: border-box;
      font-size: 14px;

      &_11 {
        @extend .food_nutrition_label_preview_container;
        font-size: 16px;
      }

      &_12 {
        @extend .food_nutrition_label_preview_container;
        font-size: 17px;
      }

      &_13 {
        @extend .food_nutrition_label_preview_container;
        font-size: 19px;
      }
    }
    &_content {
      margin-top: 8px;
      display: flex;

      &_between {
        @extend .food_nutrition_label_preview_content;
        justify-content: space-between;
      }

      &_left {
        @extend .food_nutrition_label_preview_content;
        justify-content: flex-start;
      }

      &_right {
        @extend .food_nutrition_label_preview_content;
        justify-content: flex-end;
      }

      &_margin_minus_small {
        @extend .food_nutrition_label_preview_content;
        // justify-content: flex-end;
        margin-top: -6px;
      }

      &_image_small {
        width: 32px;
        height: 32px;
        text-align: center;
      }

      &_margin_minus_default {
        @extend .food_nutrition_label_preview_content;
        // justify-content: flex-end;
        margin-top: -32px;
      }

      &_margin_minus_default2 {
        @extend .food_nutrition_label_preview_content;
        // justify-content: flex-end;
        margin-top: -24px;
      }

      &_image_default {
        width: 54px;
        height: 54px;
        text-align: center;
      }
    }
    &_label {
      width: 100px;
      justify-content: center;
      flex-wrap: wrap;
      text-align-last: justify;

      &_11 {
        @extend .food_nutrition_label_preview_label;
        width: 100px;
      }

      &_12 {
        @extend .food_nutrition_label_preview_label;
        width: 126px;
      }

      &_13 {
        @extend .food_nutrition_label_preview_label;
        width: 128px;
      }
    }
    &_value {
      margin-left: 30px;
      width: calc(100% - 118px);
    }
    &_value2 {
      width: calc(100% - 118px);
      font-size: 20px;

      &_red {
        @extend .food_nutrition_label_preview_value2;
        color: $daseruno-red;
        font-weight: bold;
      }
    }

    &_text_container {
      margin-top: 56px;
    }

    &_text {
      color: $white;
      font-weight: bold;
    }
  }

  &_print {
    &_all_container {
      // PDF1枚のpx数は width: 1144px; height: 792px;
      // 縮尺割合を変える際は、割合に沿って_print内のpx数を変えながら調整すること
      width: 1908px;
      height: 1320px;
    }
    &_sheet {
      page-break-after: always;
    }
    &_wrap {
      padding-left: 54px;
      padding-right: 54px;
      padding-top: 27px;
      padding-bottom: 27px;
      display: flex;
      flex-wrap: wrap;
    }
    &_container {
      height: 402px;
      width: 300px;
      margin-top: 12px;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      text-align: left;
      background-color: $white;
      border-radius: 8px;
      padding: 16px 20px;
      box-sizing: border-box;
      font-size: 10px;

      &_11 {
        @extend .food_nutrition_label_print_container;
        font-size: 11px;
      }

      &_12 {
        @extend .food_nutrition_label_print_container;
        font-size: 12px;
      }

      &_13 {
        @extend .food_nutrition_label_print_container;
        font-size: 13px;
      }
    }
    &_content {
      margin-top: 4px;
      display: flex;

      &_between {
        @extend .food_nutrition_label_print_content;
        justify-content: space-between;
      }

      &_left {
        @extend .food_nutrition_label_print_content;
        justify-content: flex-start;
      }

      &_right {
        @extend .food_nutrition_label_print_content;
        justify-content: flex-end;
      }

      &_margin_minus_small {
        @extend .food_nutrition_label_print_content;
        // justify-content: flex-end;
        margin-top: -4px;
      }

      &_image_small {
        width: 19px;
        height: 19px;
        text-align: center;
      }

      &_margin_minus_default {
        @extend .food_nutrition_label_print_content;
        // justify-content: flex-end;
        margin-top: -19px;
      }

      &_margin_minus_default2 {
        @extend .food_nutrition_label_print_content;
        // justify-content: flex-end;
        margin-top: -14px;
      }

      &_image_default {
        width: 32px;
        height: 32px;
        text-align: center;
      }
    }
    &_label {
      width: 60px;
      justify-content: center;
      flex-wrap: wrap;
      text-align-last: justify;

      &_11 {
        @extend .food_nutrition_label_print_label;
        width: 70px;
      }

      &_12 {
        @extend .food_nutrition_label_print_label;
        width: 78px;
      }

      &_13 {
        @extend .food_nutrition_label_print_label;
        width: 88px;
      }
    }
    &_value {
      margin-left: 20px;
      width: calc(100% - 80px);
    }
  }
}

.plan_confirm {
  &_box {
    display: flex;
    align-items: center;

    &_label {
      width: 100px;
      font-size: 14px;
      font-weight: bold;
      text-align: end;
      color: $daseruno-gray2;
    }

    &_input {
      width: 400px;
      margin-left: 24px;
      font-weight: bold;
      color: $daseruno-gray2;
      padding: 16px 24px;
      border: 2px solid $daseruno-light-gray;
      border-radius: 4px;
      height: 64px;
      box-sizing: border-box;
    }
  }

  &_table {
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 4px;
    border: 2px solid $daseruno-light-gray;
    color: $daseruno-gray2;

    &_header {
      background: $daseruno-light-gray;
      text-align: left;
    }

    &_th {
      padding-right: 60px;
      padding-left: 20px;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    &_td {
      padding-right: 60px;
      padding-left: 20px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  &_recipt_modal {
    width: 900px;
    background: #fff;
    padding: 32px 64px;
    box-sizing: border-box;
    border-radius: 24px;
    text-align: left;
    color: $daseruno-gray2;

    &_title {
      font-size: 30px;
      font-weight: bold;
    }

    &_stamp {
      width: 110px;
      height: 110px;
      position: absolute;
      right: 32px;
      top: 32px;
    }

    &_name {
      border-bottom: 1px solid $daseruno-gray2;
      text-align: right;
      font-weight: bold;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    &_company_name {
      font-weight: bold;
      font-size: 20px;
    }

    &_border_header {
      height: 24px;
    }

    &_border_wrap {
      border-top: 1px solid $daseruno-gray2;
      border-bottom: 1px solid $daseruno-gray2;
      display: flex;
      height: 172px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 4px;
    }

    &_content {
      display: flex;
      align-items: center;
      margin-top: 4px;
      margin-bottom: 4px;
    }

    &_label {
      width: 100px;
      justify-content: center;
      flex-wrap: wrap;
      text-align-last: justify;
    }

    &_value {
      margin-left: 24px;
      width: 240px;
    }

    &_price {
      font-weight: bold;
      font-size: 40px;
    }

    &_sub_price {
      font-weight: bold;
      font-size: 12px;
    }

    &_sub_price_title {
      width: 96px;
    }

    &_sub_price_label {
      width: 96px;
      text-align: end;
    }

    &_sub_price_value {
      margin-left: 8px;
      width: 60px;
    }
  }
}

.terms_privacy_policy {
  &_title {
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    margin-top: 56px;
    margin-bottom: 24px;
    color: $daseruno-gray2;
  }

  &_text {
    font-size: 14px;
    line-height: 28px;
    white-space: break-spaces;
  }

  &_content_title {
    font-size: 14px;
    line-height: 28px;
    font-weight: bold;
  }

  ol.parentheses1 {
    margin-left: 3.5em;
  }

  ol li {
    list-style-type: none;
    counter-increment: cnt;
    line-height: 28px;
  }

  ol li::before {
    content: '(' counter(cnt) ')';
    display: inline-block;
    margin-left: -3em;
    width: 3em;
  }

  ol li ol li {
    list-style-type: none;
    counter-increment: nestcnt;
    line-height: 28px;
  }

  ol li ol li::before {
    content: '(' counter(nestcnt, lower-roman) ')';
    display: inline-block;
    margin-left: -3em;
    width: 3em;
  }

  table {
    border-collapse: collapse;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  table th,
  table td {
    border: solid 1px black;
  }

  table th {
    text-align: center;
    background-color: rgba(244, 164, 96, 0.3);
  }

  table td {
    padding: 24px;
  }
}

.company {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;

  &_logo_image {
    width: 200px;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
  }

  &_label {
    width: 140px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: $sacri-red;
    color: $white;
    font-weight: bold;
    font-size: 20px;
    margin-top: 56px;
  }

  &_label_text {
    font-size: 24px;
    font-size: medium;
    color: $daseruno-gray2;
    margin-top: 24px;
  }

  &_table {
    width: 544px;

    &_content {
      display: flex;
      align-items: center;
      height: 60px;
      border-bottom: 1px solid $daseruno-gray2;

      &_left {
        width: 120px;
        color: $daseruno-gray2;
        font-size: 14px;
      }

      &_right {
        color: $daseruno-gray2;
        font-size: 14px;
      }
    }
  }
}

.material_cost {
  &_flex {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.section {
  width: 1002px;
  margin-top: 24px;
  border: 1px solid $daseruno-light-gray;
}

.simulation {
  &_select {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: start;

    &_box {
      position: relative;

      &_clear {
        position: absolute;
        left: 208px;
        top: 44px;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    &_button {
      margin-top: 28px;

      &_text_type {
        margin-left: -24px;
      }
    }
  }
}
